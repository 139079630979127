import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//Prime Template
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-purple/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
//prime flex
import "primeflex/primeflex.css";
//Custom css
import "@/assets/css/custom.css";

//component PrimVue ตัวเสริมที่ใช้บ่อยๆ ให้อัพที่นี่
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Calendar from "primevue/calendar";
import Toast from "primevue/toast";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Password from "primevue/password";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Divider from "primevue/divider";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Row from "primevue/row";
import Card from "primevue/card";
import Chip from "primevue/chip";
import Panel from "primevue/panel";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import Image from "primevue/image";
import Toolbar from "primevue/toolbar";
import ProgressSpinner from "primevue/progressspinner";
import DataView from "primevue/dataview";
import Editor from "primevue/editor";
import Inplace from "primevue/inplace";
import MultiSelect from "primevue/multiselect";
import Badge from "primevue/badge";
import print from "vue3-print-nb";
import Sidebar from "primevue/sidebar";
import Timeline from "primevue/timeline";
import Chart from "primevue/chart";
import Carousel from "primevue/carousel";
import Galleria from "primevue/galleria";
import Avatar from "primevue/avatar";
import MegaMenu from "primevue/megamenu";
import RadioButton from "primevue/radiobutton";
import Tag from "primevue/tag";
import Menu from "primevue/menu";
import Message from "primevue/message";
import InlineMessage from "primevue/inlinemessage";
import VueBarcode from "@chenfengyuan/vue-barcode";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ScrollPanel from "primevue/scrollpanel";
import ProgressBar from "primevue/progressbar";
import Fieldset from "primevue/fieldset";
import ConfirmPopup from 'primevue/confirmpopup';
import OrganizationChart from 'primevue/organizationchart';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import UseCSVButton from '@usecsv/vuejs3';
import { VueCsvImportPlugin } from "vue-csv-import";

const app = createApp(App);
//Use
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use(store);
app.use(router);
app.use(print);
app.use(VueGoogleMaps, {
    load: {
        key: ''
    }
})
app.use(VueCsvImportPlugin)

//Component
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Calendar", Calendar);
app.component("Row", Row);
app.component("InputText", InputText);
app.component("InputMask", InputMask);
app.component("Dropdown", Dropdown);
app.component("Password", Password);
app.component("Dialog", Dialog);
app.component("Divider", Divider);
app.component("InputNumber", InputNumber);
app.component("Button", Button);
app.component("Toast", Toast);
app.component("Card", Card);
app.component("Panel", Panel);
app.component("Textarea", Textarea);
app.component("InputSwitch", InputSwitch);
app.component("ConfirmDialog", ConfirmDialog);
app.component("FileUpload", FileUpload);
app.component("Image", Image);
app.component("Toolbar", Toolbar);
app.component("Chip", Chip);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Editor", Editor);
app.component("Inplace", Inplace);
app.component("DataView", DataView);
app.component("MultiSelect", MultiSelect);
app.component("Badge", Badge);
app.component("Timeline", Timeline);
app.component("Sidebar", Sidebar);
app.component("Chart", Chart);
app.component("Carousel", Carousel);
app.component("Galleria", Galleria);
app.component("Avatar", Avatar);
app.component("MegaMenu", MegaMenu);
app.component("RadioButton", RadioButton);
app.component("Tag", Tag);
app.component("Menu", Menu);
app.component("Message", Message);
app.component("InlineMessage", InlineMessage);
app.component(VueBarcode.name, VueBarcode);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("ScrollPanel", ScrollPanel);
app.component("ProgressBar", ProgressBar);
app.component("Fieldset", Fieldset);
app.component('ConfirmPopup',ConfirmPopup)
app.component('OrganizationChart',OrganizationChart);
app.component('UseCSVButton',UseCSVButton);

app.mount("#app");
