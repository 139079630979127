import { createRouter, createWebHistory } from "vue-router";

let routes = [];

routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/product/ShowView.vue"),
  },
  {
    path: "/product/add",
    name: "add-product",
    component: () => import("../views/product/AddView.vue"),
  },
  {
    path: "/percent",
    name: "percent",
    component: () => import("../components/percent/PercentProfitPOS.vue"),
  },
  {
    path: "/percent/service",
    name: "percent-service",
    component: () => import("../components/percent/PercentProfitService.vue"),
  },
  {
    path: "/type",
    name: "product-type",
    component: () => import("../components/product_nba/ProductType.vue"),
  },
  {
    path: "/category",
    name: "category-product",
    component: () => import("../views/product/CategoryView.vue"),
  },
  {
    path: "/product/detail/:product_id",
    name: "product-detail",
    component: () => import("../views/product/DetailView.vue"),
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("../views/partner/ShowView.vue"),
  },
  {
    path: "/partner/add",
    name: "add-partner",
    component: () => import("../views/partner/AddView.vue"),
  },
  {
    path: "/partner/edit/:partner_id",
    name: "edit-partner",
    component: () => import("../views/partner/EditView.vue"),
  },
  {
    path: "/partner/topup",
    name: "partner-topup",
    component: () => import("../views/partner/TopupView.vue"),
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("../views/shop/ShowView.vue"),
  },
  {
    path: "/shop/detail/:shop_id",
    name: "shop-detail",
    component: () => import("../views/shop/DetailView.vue"),
  },
  {
    path: "/shop/product/:shop_id",
    name: "shop-product",
    component: () => import("../components/shop/ProductShop.vue"),
  },
  {
    path: "/shop/report/:shop_id",
    name: "shop-report",
    component: () => import("../views/shop/ReportView.vue"),
  },
  {
    path: "/shop/product/stock/:shop_id",
    name: "shop-product-stock",
    component: () => import("../views/shop/detail/ProductStockView.vue"),
  },
  {
    path: "/shop/product/print/:preorder_id",
    name: "formproductshop",
    component: () => import("../components/formprint/FormProductshop.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () => import("../views/company/CompanyView.vue"),
  },
  {
    path: "/company/add",
    name: "add-company",
    component: () => import("../components/company/AddCompany.vue"),
  },
  {
    path: "/company/product/:company_id",
    name: "product-store",
    component: () => import("../components/company/ProductStore.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/ShowView.vue"),
  },
  {
    path: "/admin/add",
    name: "add-admin",
    component: () => import("../views/admin/addView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error-page",
    component: () => import("../views/ErrorView.vue"),
  },
  {
    path: "/preorder",
    name: "preorder",
    component: () => import("../views/preorder_nba/PreorderView.vue"),
  },
  {
    path: "/preorder/consignment",
    name: "preorder-consignment",
    component: () => import("../views/preorder_nba/PreorderconsView.vue"),
  },
  {
    path: "/preorder/detail/:preorder_id",
    name: "detailpreorder",
    component: () => import("../components/preorder_nba/DetailPre.vue"),
  },
  {
    path: "/preorder/form/:preorder_id",
    name: "formprint",
    component: () => import("../components/preorder_nba/FormPrint.vue"),
  },
  {
    path: "/preorder/formperorfer/:preorder_id",
    name: "formprint",
    component: () => import("../components/formprint/Formperorder.vue"),
  },
  {
    path: "/preorder/formorder/:preorder_id",
    name: "formorder",
    component: () => import("../components/formprint/FormOrder.vue"),
  },
  {
    path: "/order",
    name: "order",
    component: () => import("../views/order/OrderView.vue"),
  },
  {
    path: "/report",
    name: "report",
    component: () => import("../views/report/ReportView.vue"),
  },
  {
    path: "/partner/report",
    name: "report-wallet",
    component: () => import("../views/report/partner/WalletView.vue"),
  },
  {
    path: "/member/list",
    name: "member-list",
    component: () => import("../views/member/ShowView.vue"),
  },
  {
    path: "/dealers",
    name: "dealers",
    component: () => import("../views/dealers/ShowView.vue"),
  },
  {
    path: "/dealers/add",
    name: "add-dealers",
    component: () => import("../views/dealers/AddView.vue"),
  },
  {
    path: "/dealers/detail/:dealer_id",
    name: "detail-dealers",
    component: () => import("../views/dealers/DetailView.vue"),
  },
  {
    path: "/brand",
    name: "brand",
    component: () => import("../components/dealers/TabelBrand.vue"),
  },
  {
    path: "/brand/detail/:brand_id",
    name: "brand-product",
    component: () => import("../components/dealers/BrandDetail.vue"),
  },
  {
    path: "/request/dealers",
    name: "request-dealer",
    component: () => import("../views/requests/DealerRequest.vue"),
  },
  {
    path: "/request/dealers/deatail/:dealer_id",
    name: "dealer-request-detail",
    component: () => import("../components/requests/DetailDealer"),
  },
  {
    path: "/request/brand",
    name: "request-brand",
    component: () => import("../views/requests/BrandRequest.vue"),
  },
  {
    path: "/request/dealer/product",
    name: "request-dealer-product",
    component: () => import("../views/requests/ProductDealerRequest.vue"),
  },
  {
    path: "/request/dealer/product/detail/:dealerReq_id",
    name: "request-dealer-product-detail",
    component: () => import("../views/requests/dealer/RequestDetail.vue"),
  },
  {
    path: "/request/product",
    name: "request-product",
    component: () => import("../views/requests/ProductRequest.vue"),
  },
  {
    path: "/request/product/deatail/:_id",
    name: "product-request-detail",
    component: () => import("../components/requests/DetailProduct"),
  },
  {
    path: "/return/product",
    name: "return-product",
    component: () => import("../views/requests/ReturnRequest.vue"),
  },
  {
    path: "/function-more",
    name: "function-more",
    component: () => import("../components/function_more/FunctionMore.vue"),
  },
  {
    path: "/order/service",
    name: "order-service",
    component: () => import("../views/service/order_service/ListView.vue")
  },
  {
    path: "/order_cancel/service",
    name: "order_cancel-service",
    component: () => import("../views/service/order_service/ListCancelView.vue")
  },
  // ArtWork
  {
    path: "/order/artwork",
    name: "order-artwork",
    component: () => import("../views/service/artwork_service/ListView.vue"),
  },
  {
    path: "/artwork/list",
    name: "list-artwork",
    component: () => import("../views/service/artwork_service/SettingView.vue"),
  },
  {
    path: "/artwork/category",
    name: "category-artwork",
    component: () => import("../views/service/artwork_service/CategoryView.vue"),
  },
  //facecbook services
  {
    path:'/facecbook-services/settings',
    name:'facecbook-services-settings',
    component: () => import("../views/service/facebook_service/SettingsView.vue")
  },
  {
    path:'/order/facecbook-services',
    name:'order-facecbook-services',
    component: () => import("../views/service/facebook_service/ListView.vue")
  },
  //webservice services
  {
    path:'/website-services/settings',
    name:'website-services-settings',
    component: () => import("../views/service/website_service/SettingsView.vue")
  },
  {
    path:'/order/website-services',
    name:'order-website-services',
    component: () => import("../views/service/website_service/ListView.vue")
  },
  //บัญชีการเงิน services
  {
    path:'/accounting-services/settings',
    name:'accounting-services-settings',
    component: () => import("../views/service/accounting_service/SettingsView.vue")
  },
  {
    path:'/order/accounting-services',
    name:'order-accounting-services',
    component: () => import("../views/service/accounting_service/ListView.vue")
  },
  {
    path: "/accounting-services/category",
    name: "category-accounting",
    component: () => import("../views/service/accounting_service/CategoryView.vue"),
  },
  //พ.ร.บ ภาษีประกัน services
  {
    path:'/act-services/settings',
    name:'act-services-settings',
    component: () => import("../views/service/act_service/SettingsView.vue")
  },
  {
    path:'/order/act-services',
    name:'order-act-services',
    component: () => import("../views/service/act_service/ListView.vue")
  },
  {
    path: "/act-services/category",
    name: "category-act",
    component: () => import("../views/service/act_service/CategoryView.vue"),
  },
  {
    path:'/tax-services/settings',
    name:'tax-services-settings',
    component: () => import("../views/service/tax_service/SettingsView.vue")
  },
  {
    path: "/order/tax-service",
    name: "order-tax-services",
    component: () => import("../views/service/tax_service/ListView.vue"),
  },
  {
    path:'/tax-services/category',
    name:'category-tax',
    component: () => import("../views/service/tax_service/CategoryView.vue")
  },
  {
    path:'/insurance-services/settings',
    name:'insurance-services-settings',
    component: () => import("../views/service/insurance_service/SettingsView.vue")
  },
  {
    path: "/insurance-services/category",
    name: "category-insurance",
    component: () => import("../views/service/insurance_service/CategoryView.vue"),
  },
  {
    path: "/order/insurance-services",
    name: "order-insurance-service",
    component: () => import("../views/service/insurance_service/ListView.vue"),
  },
  //itspport services
  {
    path:'/itsupport-services/settings',
    name:'itsupport-services-settings',
    component: () => import("../views/service/itsupport_service/SettingsView.vue")
  },
  {
    path:'/order/itsupport-services',
    name:'order-itsupport-services',
    component: () => import("../views/service/itsupport_service/ListView.vue")
  },
  //insurance-life
  {
    path:'/insurance-life-services/settings',
    name:'insurance-life-services-settings',
    component: () => import("../views/service/insurance_life_service/SettingsView.vue")
  },
  {
    path:'/insurance-life-company/settings',
    name:'insurance-life-company-settings',
    component: () => import("../views/service/insurance_life_service/CategoryView.vue")
  },


  //Photo services
  {
    path:'/photo-services/settings',
    name:'photo-services-settings',
    component: () => import("../views/service/photo_service/SettingsView.vue")
  },
  {
    path:'/order/photo-services',
    name:'order-photo-services',
    component: () => import("../views/service/photo_service/ListView.vue")
  },


  // ตัดรอบ
  {
    path: "/cutoff",
    name: "cutoff",
    component: () => import("../views/cutoff/CutoffView"),
  },
  {
    path: "/cutoff/invoice",
    name: "cutoff-invoice",
    component: () => import("../views/cutoff/InvoiceView.vue"),
  },
  // ขนส่ง
  {
    path: "/express/booking-parcel",
    name: "booking-parcel",
    component: () => import("../views/express/booking/BookingParcelView.vue"),
  },
  {
    path: "/express/percent-courier",
    name: "percent-courier",
    component: () => import("../views/express/percent/PercentCourierView.vue"),
  },
  {
    path: "/express/report",
    name: "express-report",
    component: () => import("../views/report/express/ExpressView.vue"),
  },
  // ตั๋วเครื่องบิน
  {
    path: "/aoc/flight-booking",
    name: "light-booking",
    component: () => import("../views/aoc/booking/FlightBookingView.vue"),
  },
  //counter service
  {
    path: "/counter-service/order",
    name: "counter-service",
    component: () => import("../views/counter_service/OrdercsView.vue"),
  },
  {
    path: "/counter-service/order/sot",
    name: "counter-service-sot",
    component: () => import("../views/counter_service/OrdersotView.vue"),
  },
  {
    path: "/counter-service/order/nba",
    name: "counter-service-nba",
    component: () => import("../views/counter_service/OrdernbaView.vue"),
  },
  {
    path: "/counter-service/service",
    name: "counter-service-percent",
    component: () => import("../views/counter_service/ServiceView.vue"),
  },
  {
    path: "/counter-service/topup",
    name: "counter-service-topup",
    component: () => import("../views/counter_service/TopupView.vue"),
  },
  {
    path: "/more/service_nba",
    name: "more-service-nba",
    component: () => import("../views/counter_service/ServiceNbaView.vue"),
  },
  {
    path : "/thailand",
    name : "thailand",
    component : ()=>import('../views/thailand/ThailandView.vue')
  }
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
