<template>
  <div class="grid">
    <div class="col-12 lg:col-6 lg:col-offset-3 mt-3">
      <Card class="border-1 shadow-none border-gray-400">
        <template #content>
            <div class="field text-center">
              <h2>ระบบจัดการหลังบ้าน NBA-SHOP</h2>
              <p>บริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด</p>
            </div>
            <div class="field mt-4">
              <label>ชื่อผู้ใช้งาน :</label>
              <InputText type="text" v-model="username" placeholder="กรอกชื่อผู้ใช้งาน" class="w-full font" @keyup.enter="login()"/>
            </div>
            <div class="field">
              <label>รหัสผ่าน :</label>
              <InputText type="password" v-model="password" placeholder="กรอกรหัสผ่าน" class="w-full font" @keyup.enter="login()"/>
            </div>
        </template>
        <template #footer>
          <Button label="เข้าสู่ระบบ" class="w-full" icon="pi pi-sign-in" @click="login()" :loading="isLoading" @keyup="login()"/>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  created(){
    document.title = "เข้าสู่ระบบ | NBA SHOP BACK OFFICE"
  },
  data:()=>({
    isLoading: false,
    username : "",
    password : ""
  }),
  methods: {
    async login(){
      if(this.username==="" || this.password ===""){
        this.$toast.add({severity:'warn', summary: 'แจ้งเตือน', detail:'กรุณากรอกชื่อผู้ใช้งานและรหัสผ่าน', life: 3000});
        return false;
      }
      this.isLoading = true;
      await axios.post(`${process.env.VUE_APP_SHOP}login`,{
        username : this.username,
        password : this.password
      }).then((res)=>{
        this.isLoading = false;
        localStorage.setItem('token', res.data.token);
        window.location.assign('/');
      }).catch((err)=>{
        console.log(err);
        this.isLoading = false;
        if(err.response.status === 401){
          this.$toast.add({severity:'error', summary: 'ไม่สำเร็จ', detail:'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง', life: 3000});
        }else{
          this.$toast.add({severity:'error', summary: 'ผิดพลาด', detail:err.response.data.message, life: 3000});
        }
        
      })
    }
  }
};
</script>

<style>
</style>