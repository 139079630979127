<template>
  <div v-if="$store.getters.logedIn">
    <NavbarTemplate class="none" />
    <router-view />
  </div>
  <div v-else>
    <LoginPage /> 
  </div>
  <Toast />
  <ConfirmDialog />
  <!-- dialog loading-->
  <Dialog class="border-0 shadow-none" :draggable="false" v-model:visible="$store.getters.loading" :closable="false"
    :modal="true">
    <ProgressSpinner style="width: 80px; height: 80px" strokeWidth="5" fill="var(--surface-ground)"
      animationDuration=".5s" aria-label="Custom ProgressSpinner" /><br />
  </Dialog>
</template>
<script>
import NavbarTemplate from "@/components/template/NavbarTemplate.vue";
import LoginPage from "@/views/LoginView.vue";
import axios from "axios";
export default {
  components: {
    NavbarTemplate,
    LoginPage,
  },
  created() {
    document.title = "ระบบจัดการ NBA-Shop";
  },
  async mounted() {
    if (localStorage.getItem("token")) {
      await axios
        .post(`${process.env.VUE_APP_SHOP}me`, null, {
          headers: {
            "auth-token": `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          const Admin = {
            name: res.data.name,
            level: res.data.level,
            position: res.data.position
          }
          if (Admin.level === 'admin') {
            this.$store.commit("setAdmin", Admin);
            this.$store.commit("setLogin", true);
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    } else {
      this.$router.push("/login");
    }
  },
};
</script>
<style></style>
