import { createStore } from 'vuex'

export default createStore({
  state: {
    logedIn : false,
    token : localStorage.getItem('token'),
    loading: false,
    admin : {
      name : "",
      level : "",
      position : ""
    }
  },
  getters: {
    logedIn:state=>state.logedIn,
    token:state=>state.token,
    admin:state=>state.admin,
    loading : state=>state.loading
  },
  mutations: {
    setLoading(state, item){
      state.loading = item
    },
    setLogin(state, item){
       state.logedIn = item;
    },
    setAdmin(state, item){
      state.admin.name=item.name;
      state.admin.level=item.level;
      state.admin.position=item.position
    },
    setToken(state, item){
      state.token=item
    },
    setLoginDefault(state){
      state.token = "";
      state.admin.name ="";
      state.admin.level ="";
      state.admin.position = "";
      state.logedIn = false;
    }
  },
  actions: {
  },
  modules: {
  }
})
