<template>
  <div>
    <Menubar :model="items" class="custom-menubar">
      <template #start>
        <img alt="logo" :src="require('@/assets/nbadigital.png')" class="logo" />
      </template>
      <template #end>
        <Button class="p-button-danger" label="ออกจากระบบ" icon="pi pi-power-off" @click="logout()" />
      </template>
    </Menubar>
  </div>
</template>
<script>
import Menubar from "primevue/menubar";
import { ref } from "vue";
export default {
  components: {
    Menubar,
  },
  setup() {
    const items = ref([
      {
        label: "หน้าแรก",
        icon: "pi  pi-fw pi-fw pi-home",
        to: "/",
      },
      {
        label: "POS",
        icon: "pi pi-fw pi-shopping-cart",
        items: [
          {
            label: "Pre-Order (เครดิต)",
            icon: "pi pi-fw pi-circle",
            to: "/preorder",
          },
          {
            label: "Pre-Order (ฝากขาย)",
            icon: "pi pi-fw pi-circle",
            to: "/preorder/consignment",
          },
          {
            label: "รายการออเดอร์",
            icon: "pi pi-fw pi-circle",
            to: "/order",
          },
          {
            label: "สินค้า",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "เพิ่มสินค้า",
                to: "/product/add",
              },
              {
                label: "สินค้าทั้งหมด",
                to: "/product",
              },
              {
                label: "หมวดหมู่สินค้า",
                to: "/category",
              },
              {
                label: "ประเภทสินค้า",
                to: "/type",
              },
            ],
          },
          {
            label: "คู่ค้า (Dealer)",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "คู่ค้าทั้งหมด (Daler)",
                to: "/dealers",
              },
              {
                label: "เพิ่มคู่ค้า (Dealer)",
                to: "/dealers/add",
              },
              {
                label: "แบรนด์ทั้งหมด",
                to: "/brand",
              },
            ],
          },
          {
            label: "คำร้องขอ",
            icon: "pi pi-fw  pi-circle",
            items: [
              {
                label: "คำร้องขอจากคู่ค่า (Dealer)",
                icon: "pi pi-fw pi-circle",
                items: [
                  {
                    label: "คำร้องขอเป็นคู่ค้า (Dealer)",
                    to: "/request/dealers",
                  },
                  {
                    label: "คำร้องขอฝากขายสินค้า",
                    to: "/request/dealer/product",
                  }
                ],
              },
              {
                label: "คำร้องของจากร้านค้า (Shop)",
                icon: "pi pi-fw pi-circle",
                items: [
                  {
                    label: "คำร้องขอนำสินค้าวางขาย",
                    to: "/request/product",
                  },
                  {
                    label: "คำร้องขอคืนสินค้า",
                    to: "/return/product",
                  },
                ],
              },
            ],
          },
          {
            label: "การตัดรอบ",
            icon: "pi  pi-fw pi-circle",
            items: [
              {
                label: "ตัดรอบบิล",
                to: "/cutoff",
              },
              {
                label: "ใบแจ้งหนี้ร้านค้า",
                to: "/cutoff/invoice",
              },
            ],
          },
          {
            separator: true,
          },

          {
            label: "รายงาน",
            icon: "pi pi-fw pi-circle",
            to: "/report",
          },
          {
            label: "จัดการ",
            icon: "pi pi-fw  pi-circle",
            items: [
              {
                label: "เปอร์เซ็นต์กำไร",
                to: "/percent",
              },
            ],
          },
        ],
      },
      {
        label: "ขนส่ง",
        icon: "pi pi-fw pi-truck",
        items: [
          {
            label: "รายการส่งพัสดุ",
            icon: "pi  pi-fw pi-circle",
            to: "/express/booking-parcel",
          },
          {
            separator: true,
          },
          {
            label: "รายการพัสดุทั้งหมด",
            icon: "pi pi-fw pi-circle",
            to: "/express/report",
          },
          {
            label: "จัดการ",
            icon: "pi pi-fw  pi-circle",
            items: [
              {
                label: "เปอร์เซ็นต์ขนส่ง",
                to: "/express/percent-courier",
              },
            ],
          },
        ],
      },
      // {
      //   label: "จองตั๋ว",
      //   icon: "pi pi-book",
      //   items: [
      //     {
      //       label: "รายการจองตั๋ว",
      //       icon: "pi  pi-fw pi-circle",
      //       to: "/aoc/flight-booking",
      //     },
      //     {
      //       separator: true,
      //     },
      //     // {
      //     //   label: "รายการพัสดุทั้งหมด",
      //     //   icon: "pi pi-fw pi-circle",
      //     //   to: "/express/report",
      //     // },
      //     // {
      //     //   label: "จัดการ",
      //     //   icon: "pi pi-fw  pi-circle",
      //     //   items: [
      //     //     {
      //     //       label: "เปอร์เซ็นต์ขนส่ง",
      //     //       to: "/express/percent-courier",
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      {
        label: "เคาน์เตอร์เซอร์วิส",
        icon: "pi pi-fw pi-desktop",
        items: [
          {
            label: "ทั้งหมด",
            icon: "pi pi-fw pi-circle",
            to: "/counter-service/order",
          },
          {
            label: "รายการฝากจ่าย",
            icon: "pi pi-fw pi-circle",
            to: "/counter-service/order/nba",
          },
          {
            label: "เพิ่มเติม",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "ฝากจ่าย NBA",
                to: "/counter-service/order/nba",
              },
              {
                label: "บริษัท มีเดีย (SOT)",
                to: "/counter-service/order/sot",
              },
            ],
          },
          {
            separator: true,
          },
          {
            label: "รายงาน",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "รายงานการเติมเงินมือถือ",
                to: "/counter-service/topup",
              },
            ],
          },
          {
            label: "จัดการ",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "ส่วนแบ่งของ (SOT)",
                to: "/counter-service/service",
              },
              {
                label: "เซอร์วิสฝากจ่าย NBA",
                to: "/more/service_nba",
              },
            ],
          },
        ],
      },
      {
        label: "จองตั๋วเครื่องบิน",
        icon: "pi pi-fw pi-ticket",
        items: [
          {
            label: "รายการจองตั๋วเครื่องบิน",
            icon: 'pi pi-fw pi-circle',
            to: "/aoc/flight-booking",
          },
        ]
      },
      {
        label: "NBA service",
        icon: 'pi pi-fw pi-shopping-bag',
        items: [
          {
            label: "ออกแบบสื่อสิ่งพิมพ์",
            icon: 'pi pi-fw pi-circle',
            items: [
              // {
              //   label: "รายการออกสื่อสิ่งพิมพ์",
              //   icon: "pi pi-fw pi-image",
              //   to: "/order/artwork",
              // },
              {
                label: "สื่อสิ่งพิมพ์ทั้งหมด",
                icon: "pi pi-fw pi-image",
                to: "/artwork/list",
              },
              {
                label: "ประเภทสื่อสิ่งพิมพ์",
                icon: "pi pi-fw pi-image",
                to: "/artwork/category",
              },
            ]
          },
          {
            label: "สื่อโฆษณาออนไลน์",
            icon: 'pi pi-fw pi-circle',
            items: [
              // {
              //   label: "รายงานสื่อโฆษณาออนไลน์",
              //   icon: 'pi pi-fw pi-facebook',
              //   to: '/order/facecbook-services'
              // },
              {
                label: "สื่อโฆษณาออนไลน์ ทั้งหมด",
                icon: 'pi pi-fw pi-facebook',
                to: '/facecbook-services/settings'
              }
            ]
          },
          {
            label: "ออกแบบเว็บไซต์",
            icon: 'pi pi-fw pi-circle',
            items: [
              // {
              //   label: "รายงาน ออกแบบเว็บไซต์",
              //   icon: 'pi pi-fw pi-globe',
              //   to: '/order/website-services'
              // },
              {
                label: "ออกแบบเว็บไซต์ ทั้งหมด",
                icon: 'pi pi-fw pi-globe',
                to: '/website-services/settings'
              }
            ]
          },
          {
            label: "บริการด้านบัญชี",
            icon: 'pi pi-fw pi-circle',
            items: [
              // {
              //   label: "รายงาน บริการด้านบัญชี",
              //   icon: 'pi pi-fw pi-book',
              //   to: '/order/accounting-services'
              // },
              {
                label: "บริการด้านบัญชี ทั้งหมด",
                icon: 'pi pi-fw pi-book',
                to: '/accounting-services/settings'
              },
              {
                label: "ประเภท บริการด้านบัญชี",
                icon: 'pi pi-fw pi-book',
                to: '/accounting-services/category'
              }
            ]
          },
          {
            label: "IT-Support",
            icon: 'pi pi-fw pi-circle',
            items: [
              // {
              //   label: "รายงาน IT-Support",
              //   icon: 'pi pi-fw pi-wrench',
              //   to: '/order/itsupport-services'
              // },
              {
                label: "IT-Support settings",
                icon: 'pi pi-fw pi-wrench',
                to: '/itsupport-services/settings'
              }
            ]
          },
          {
            label: "พ.ร.บ ภาษีประกัน",
            icon: 'pi pi-fw pi-circle',
            items: [
              {
                label: "พ.ร.บ",
                icon: 'pi pi-fw pi-car',
                items: [
                  // {
                  //   label: "รายงานบริการ พ.ร.บ.",
                  //   icon: 'pi pi-fw pi-car',
                  //   to: '/order/act-services',
                  // },
                  {
                    label: "บริการ พ.ร.บ.",
                    icon: 'pi pi-fw pi-car',
                    to: '/act-services/settings',
                  },
                  {
                    label: "ประเภท พ.ร.บ",
                    icon: 'pi pi-fw pi-car',
                    to: '/act-services/category'
                  },
                  {
                    label: "ประเภทรถ",
                    icon: "pi pi-fw pi-car",
                    to: "/act-services/typecar"
                  }
                ]
              },
              {
                label: "ภาษี",
                icon: 'pi pi-fw pi-car',
                items: [
                  // {
                  //   label: "รายงานบริการ ภาษี",
                  //   icon: 'pi pi-fw pi-car',
                  //   to: '/order/tax-service',
                  // },
                  {
                    label: "บริการต่อภาษี",
                    icon: 'pi pi-fw pi-car',
                    to: '/tax-services/settings',
                  },
                  {
                    label: "ประเภทภาษี",
                    icon: 'pi pi-fw pi-car',
                    to: '/tax-services/category',
                  },
                ]
              },
              {
                label: "ประกัน",
                icon: 'pi pi-fw pi-car',
                items: [
                  // {
                  //   label: "รายงานบริการ ประกัน",
                  //   icon: 'pi pi-fw pi-car',
                  //   to: '/order/insurance-services',
                  // },
                  {
                    label: "บริการ ประกัน",
                    icon: 'pi pi-fw pi-car',
                    to: '/insurance-services/settings',
                  },
                  {
                    label: "ประเภท ประกัน",
                    icon: 'pi pi-fw pi-car',
                    to: '/insurance-services/category'
                  }
                ]
              }
            ]
          },
          {
            label: "ประกัน (ชีวิต)",
            icon: 'pi pi-fw pi-circle',
            items: [
              // {
              //   label: "รายงานประกัน (ชีวิต)",
              //   icon: 'pi pi-heart-fill',
              //   to: '/insurance-life-services/settings'
              // },
              {
                label: "บริษัทประกัน (ชีวิต)",
                icon: 'pi pi-heart-fill',
                to: '/insurance-life-company/settings'
              }
            ]
          },
          {
            label: "รูปติดบัตร",
            icon: 'pi pi-fw pi-circle',
            items: [
              // {
              //   label: "รายงานรูปติดบัตร",
              //   icon: 'pi pi-images',
              //   to: '/order/photo-services'
              // },
              {
                label: "รูปติดบัตรทั้งหมด",
                icon: 'pi pi-images',
                to: '/photo-services/settings'
              }
            ]
          },
          {
            separator: true,
          },
          {
            label: "รายงาน",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "รายงาน Service ทั้งหมด",
                to: "/order/service",
              },
              {
                label: "Service ที่ถูกยกเลิก",
                to: "/order_cancel/service",
              },
            ],
          },
          {
            label: "จัดการ",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "เปอร์เซ็นต์กำไร",
                to: "/percent/service",
              },
            ],
          },
        ]
      },

      {
        label: "จัดการผู้ใช้งาน",
        icon: "pi pi-fw pi-users",
        items: [
          {
            label: "เพิ่มพาร์ทเนอร์",
            icon: "pi pi-fw pi-circle",
            to: "/partner/add",
          },
          {
            label: "พาร์ทเนอร์ทั้งหมด",
            icon: "pi pi-fw pi-circle",
            to: "/partner",
          },
          {
            label: "ร้านค้า",
            icon: "pi pi-fw pi-circle",
            to: "/shop",
          },
          {
            label: "รายการเติมเงินเข้ากระเป๋า",
            icon: "pi pi-fw pi-circle",
            to: "/partner/topup",
          },
          {
            separator: true,
          },
          {
            label: "Platfrom ทั้งหมด",
            icon: "pi pi-fw pi-circle",
            to: "/member/list",
          },
          {
            separator: true,
          },
          {
            label: "รายงาน",
            icon: "pi pi-fw pi-circle",
            to: "/partner/report",
          },
        ],
      },
      {
        label: "จัดการระบบ",
        icon: "pi pi-fw pi-cog",
        items: [
          {
            label: "ผู้ใช้งาน (Admin)",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "ผู้ใช้งานทั้งหมด",
                to: "/admin",
              },
              {
                label: "เพิ่มผู้ใช้งานระบบ",
                to: "/admin/add",
              },
            ],
          },
          {
            separator: true,
          },
          {
            label: "จัดการ",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "ฐานข้อมูลประเทศไทย",
                to: "/thailand"
              },
              {
                label: "ฟังก์ชั่นเพิ่มเติม",
                to: "/function-more",
              },
            ],
          },
        ],
      },
    ]);
    return { items };
  },
  methods: {
    logout() {
      this.$confirm.require({
        message: "ต้องการออกจากระบบนี้?",
        header: "ออกจากระบบ",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "ออกจากระบบ",
        acceptClass: "p-button-danger",
        acceptIcon: "pi pi-fw pi-power-off",
        rejectLabel: "ยกเลิก",
        accept: async () => {
          localStorage.clear();
          this.$store.commit("setLoginDefault");
          this.$router.push("/login");
        },
      });
    },
  },
};
</script>

<style scope>
.custom-menubar {
  height: 90px;
}

.logo {
  width: 140px;
  height: 65px;
  margin-right: 10px;
}

@media print {
  .none {
    display: none;
  }
}
</style>
